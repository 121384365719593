<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Bon de Commande N° {{ order.number }}
          </h2>
        </div>
        <div>
          <el-button type="info" @click="exportOrderPDF" icon="el-icon-printer"
            >Imprimer</el-button
          >
          <el-button @click="handleView" icon="el-icon-view" plain
            >Aperçu</el-button
          >

          <el-button
            type="primary"
            v-if="order.status == 'DRAFT'"
            @click="handleValidDoc"
            :loading="validLoanding"
            >Confirmer la commande</el-button
          >

          <el-button
            type="primary"
            v-if="order.status == 'OPEND' || order.status == 'IN_PROGRESS'"
            @click="handleConverToInvoice"
            icon="el-icon-tickets"
            >Créer une facture</el-button
          >

          <el-dropdown class="mx-1" split-button @command="handleAction">
            Action
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="close-saleorder"
                :disabled="order.status === 'CLOSED'"
                >Fermer la commande
              </el-dropdown-item>
              <el-dropdown-item command="copy-saleorder"
                >Dupliquer</el-dropdown-item
              >
              <el-dropdown-item
                :disabled="order.status !== 'DRAFT'"
                command="update"
              >
                Modifier
              </el-dropdown-item>
              <el-dropdown-item
                command="cancel"
                :disabled="
                  order.status === 'CANCELED' || order.status === 'IN_PROGRESS'
                "
                >Annuler
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="mb-10">
      <div class="flex shadow flex-col mx-auto bg-white p-5 border">
        <el-tabs v-model="activeName" @tab-click="handleTab">
          <el-tab-pane label="Aperçu" name="ORDER"></el-tab-pane>
          <el-tab-pane label="Factures" name="INVOICE"></el-tab-pane>
        </el-tabs>
        <div v-if="activeName == 'ORDER'">
          <div class="flex justify-between items-start">
            <div class="flex flex-col w-1/2">
              <h1
                class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8"
              >
                {{ order.contact_name }}
              </h1>
              <div class="flex flex-col bg-gray-100 w-2/3 p-3">
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">N°</span>
                  <span class="text-blue-500">{{ order.contact_no }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">Téléphone</span>
                  <span>{{ order.contact_phone }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">Email</span>
                  <span>{{ order.contact_email }}</span>
                </div>
                <div class="flex justify-between">
                  <span class="text-gray-500">Adresse</span>
                  <span>{{ order.contact_street }}</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-end items-end w-1/2">
              <div
                class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8"
              >
                Total TTC :
                {{ order.total | moneyFilter }}
                {{ order.currency }}
              </div>
              <div class="flex flex-col w-2/3 p-3">
                <div class="flex justify-between mb-3">
                  <span class="text-gray-500">Date création :</span>
                  <span>{{ order.create_at }}</span>
                </div>
                <div class="flex justify-between mb-3">
                  <span class="text-gray-500">Date :</span>
                  <span>{{ order.date }}</span>
                </div>

                <div class="flex justify-between mb-3">
                  <span class="text-gray-500">Date d'expiration :</span>
                  <span>{{ order.due_date }}</span>
                </div>

                <div class="flex justify-between mb-3">
                  <span class="text-gray-500">Statut</span>
                  <Status :name="order.status" />
                </div>

                <div class="flex justify-between">
                  <span class="text-gray-500">commentaire</span>
                  <p class="font-semibold">{{ order.comment }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="customer-white">
            <el-table
              :data="order.line_items"
              style="width: 100%"
              class="mt-10"
            >
              <el-table-column
                prop="warehouse_code"
                label="CODE ENT"
                width="120"
              >
                <template slot-scope="{ row }">
                  <span class="text-blue">{{ row.warehouse_code }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="N° ARTICLE" width="140">
                <template slot-scope="scope">
                  {{ scope.row.article_sku }}
                </template>
              </el-table-column>
              <el-table-column
                prop="article_name"
                label="DÉSIGNATION"
                min-width="250"
              >
                <template slot-scope="{ row }">
                  <div class="flex flex-col justify-start">
                    <div class="mb-1">{{ row.article_name }}</div>

                    <p class="text-gray-400">
                      {{ row.article_description }}
                    </p>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="quantity"
                align="center"
                label="QUANTITÉ"
                width="140"
              >
                <template slot-scope="{ row }">
                  <div class="input-transparent">
                    <span>{{ row.quantity }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="quantity"
                align="center"
                label="QTE.FACTURÉE"
                width="150"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.quantity_invoiced }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                align="right"
                label="PRIX"
                width="140"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.price_sales | moneyFilter }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="taxe"
                align="center"
                label="REMISE "
                width="120"
              >
                <template slot-scope="{ row }">
                  <span v-if="row.percent_discount"
                    >{{ row.rate_discount }} %</span
                  >
                  <span v-if="!row.percent_discount"
                    >{{ row.discount }} F
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="taxe"
                align="center"
                label="TAXE "
                width="80"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.percent_tax }} %</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                align="right"
                label="MONTANT"
                width="150"
              >
                <template slot-scope="{ row }">
                  <span
                    >{{ row.total | moneyFilter }}
                    {{ order.currency }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="flex justify-between items-start mb-2 border-b my-8">
            <div class="flex flex-col w-3/4"></div>
            <div class="flex flex-col w-1/4 justify-start">
              <div class="flex justify-between">
                <span>Total HT:</span>
                <span> {{ order.sub_total | moneyFilter }} </span>
              </div>
              <div class="flex justify-between pt-2 mt-4 text-gray-500">
                <span class="mr-4"
                  >Remise
                  <span v-if="order.percent_discount"
                    >({{ order.rate_discount }} %)</span
                  >
                  :</span
                >
                <span>{{ order.discount | moneyFilter }} </span>
              </div>
              <div class="flex justify-between mt-4 text-gray-500">
                <span class="mr-4">Taxe ({{ order.percent_tax }}%):</span>
                <span>{{ order.total_tax | moneyFilter }} </span>
              </div>
              <div class="flex justify-between mt-4 text-gray-500">
                <span class="mr-4">Autre coût:</span>
                <span>{{ order.shipping_cost | moneyFilter }} </span>
              </div>
              <div
                class="flex justify-between font-medium leading-7 text-gray-900 py-4 border-b"
              >
                <span>Total TTC:</span>
                <span>
                  {{ order.total | moneyFilter }}
                  {{ order.currency }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <el-table
          v-if="activeName == 'INVOICE'"
          :data="invoices"
          style="width: 100%"
          v-loading="listLoading"
        >
          <el-table-column prop="invoice_no" label="N° FACTURE" width="160">
            <template slot-scope="{ row }">
              <router-link :to="`/sales/invoice/` + row._id">
                <span class="text-blue-500">{{ row.number }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="DATE" width="160">
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Écheance" label="ÉCHEANCE" width="140">
            <template slot-scope="{ row }">
              <div class="flex flex-col items-center justify-between">
                <span v-if="row.due_date !== null">{{ row.due_date }}</span>
                <span v-else>-</span>
                <span
                  v-if="activeName === 'UNPAID'"
                  class="text-xs text-red-400"
                >
                  {{ row.due_date | dayRetard }} Jour(s)</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="is_active"
            align="center"
            label="STATUT"
            width="120"
          >
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column
            prop="selling_price"
            label="TOTAL "
            align="right"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total | moneyFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="selling_price"
            label="AVANCE "
            align="right"
            width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.amount_to_pay | moneyFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_wt"
            label="À REGLER "
            width="150"
            align="right"
          >
            <template slot-scope="{ row }">
              <span class="font-bold"
                >{{ (row.total - row.amount_to_pay) | moneyFilter }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="`Convertir la commande N° ${order.number} en  Facture`"
      :visible.sync="dialogInvoice"
      width="55%"
      top="4vh"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="invoice"
        :rules="rules"
        ref="invoiceForm"
        :label-position="labelPosition"
      >
        <div class="dialog_content_height">
          <div class="flex justify-between">
            <div class="flex justify-between">
              <el-form-item class="mr-4" prop="invoice_no" label="Facture N°">
                <el-input v-model="invoice.document_no" :readonly="true">
                </el-input>
              </el-form-item>
              <el-form-item class="mr-4" prop="invoice_date" label="Date">
                <el-date-picker
                  v-model="invoice.date"
                  type="date"
                  placeholder="jj-mm-aaaa"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item
                class="mr-4"
                prop="invoice_deadline"
                label="Date d'échéance"
              >
                <el-date-picker
                  v-model="invoice.due_date"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="article-items">
            <el-table border :data="invoice.line_items" style="width: 100%">
              <el-table-column prop="" label="N°" width="80">
                <template slot-scope="{ row }">
                  {{ row.article_no }}
                </template>
              </el-table-column>
              <el-table-column
                prop="article_name"
                label="Article"
                min-width="200"
              >
                <template slot-scope="{ row }">
                  <div class="flex flex-col justify-start">
                    <div class="mb-1">{{ row.article_name }}</div>

                    <p class="text-gray-400">
                      {{ row.article_description }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="Quantité"
                align="cente"
                label="Quantité"
                width="130"
              >
                <template slot-scope="{ row }">
                  <div class="input-transparent">
                    <el-input-number
                      size="medium"
                      :controls="false"
                      v-model="row.quantity"
                      :max="row.res_quantity"
                      :min="1"
                    />
                  </div>
                </template>
              </el-table-column>
              <!--   <el-table-column
                prop="QTE RESTANT"
                align="right"
                label="QTE RESTANT"
                width="140"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.res_quantity }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="prix"
                align="center"
                label="TAXE"
                width="120"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.percent_tax | moneyFilter }}%</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="prix"
                align="right"
                label="PRIX "
                width="130"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.price | moneyFilter }}</span>
                </template>
              </el-table-column>

              <el-table-column align="right" label="TOTAL" width="120">
                <template slot-scope="{ row }">
                  <span>{{
                    (row.price * row.quantity -
                      (row.price * row.percent_discount) / 100)
                      | moneyFilter
                  }}</span>
                </template>
              </el-table-column> -->
              <el-table-column align="center" width="50">
                <template slot-scope="{ row }">
                  <i
                    @click="handleDeleteItemInvoice(row.article_id)"
                    class="el-icon-circle-close text-red-500 text-2xl cursor-pointer mr-2"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
      <div slot="footer">
        <div class="flex justify-between">
          <div
            class="text-xl font-semibold leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            Total TTC : {{ totalTTC | moneyFilter }}
          </div>
          <div>
            <el-button @click="dialogInvoice = false">Annuler</el-button>
            <el-button
              type="primary"
              :loading="loadingInvoice"
              @click="createInvoice('invoiceForm')"
              >Enregistrer</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import { exportOrderPDF } from "@/api/export";
import {
  getDocument,
  copyDocument,
  cancelDocument,
  validDocument,
  closeDocument,
  getDocumentSaleNo,
  converOrderToInvoice,
  getAllDocumentReference,
} from "@/api/sales2";
import Loading from "@/components/Loanding/Full";
import { formatDate, getDayRetard } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import PDFView from "@/components/PDF/view";

export default {
  name: "orderFiche",
  filters: {
    filterTime: formatDate,
    dayRetard: getDayRetard,
    moneyFilter: toThousandFilter,
  },
  components: { Loading, Status, PDFView },

  data() {
    return {
      typeDoc: "SALE_ORDER",
      urlPDF: null,
      dialogViewPDF: false,
      loadingFull: false,
      printLoanding: false,
      validLoanding: false,
      totalItem: 0,
      dialogTransVisible: false,
      activeName: "ORDER",
      dialogPaymentestimate: false,
      labelPosition: "top",
      paymentestimateLoading: false,
      listPaymentLoading: false,
      copyLoading: false,
      dialogInvoice: false,
      loadingInvoice: false,
      listLoading: false,
      invoice: {
        due_date: "",
        date: formatDate(new Date()),
        document_no: "",
        shipping_cost: 0,
        line_items: [],
      },
      order: {
        number: "",
        comment: null,
        _id: "",
        billing_to: "",
        contact_id: "",
        contact_name: "",
        contact_no: "",
        contact_phone: "",
        contact_street: "",
        currency: "",
        date: "",
        reference: "",
        terms: "",
        due_date: "",
        shipping_cost: 0.0,
        shipping_to: "",
        status: "",
        percent_discount: 0,
        percent_tax: 0,
        total: 0.0,
        total_tax: 0.0,
        total_discount: 0.0,
        sub_total: 0.0,
        total_profit: 0.0,
        line_items: [],
      },
      accounts: [],
      invoices: [],

      rules: {
        date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        document_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],
      },
    };
  },
  computed: {
    totalHT: function () {
      var subtotal = 0;

      this.invoice.line_items.forEach((el) => {
        let totalHT = el.quantity * el.price;
        console.log(totalHT);
        let discount = 0;
        let tax;
        if (el.percent_discount) {
          discount = (totalHT * el.discount) / 100;
          console.log(el.discount, "discount " + discount);
        } else {
          discount = el.discount;
        }
        tax = ((totalHT - discount) * el.percent_tax) / 100;
        subtotal += totalHT - discount + tax;
        //console.log(subtotal);
      });

      return Math.floor(subtotal);
    },
    totalTTC: function () {
      var total = 0;
      if (this.order.percent_discount) {
        total =
          this.totalHT +
          this.order.shipping_cost -
          (this.totalHT * this.order.rate_discount) / 100 +
          (this.totalHT * this.order.percent_tax) / 100;
      } else {
        total =
          this.totalHT -
          this.order.discount +
          this.order.shipping_cost +
          (this.totalHT * this.order.percent_tax) / 100;
        console.log(this.order);
      }
      return total;
    },
    itemsNoEmpty: function () {
      return this.invoice.items.some((item) => item.article_id !== "");
    },
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getDocument(id, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.loadingFull = false;
            this.order = Object.assign({}, res.data);
          }, 1.5 * 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.loadingFull = false;
            this.$message({
              message: err.data.message,
              type: "error",
            });
          }, 1.5 * 1000);
        });
    },

    handleClosedOrder() {
      const h = this.$createElement;
      this.$msgbox({
        title: "BON DE COMMANDE N° " + this.order.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler ce bon de commande ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            closeDocument(this.order._id, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "La commande  a été fermée",
                    type: "success",
                  });
                  this.order.status = "CLOSED";
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleCopyOrder() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Bon de commande N° " + this.order.number,
        message: h("div", null, [
          h(
            "span",
            null,
            " Voulez vous vraiment dupliquer ce bon de commande  ?"
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyDocument(this.order._id, this.typeDoc)
              .then((res) => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "Bon de commande a été dupliqué",
                    type: "success",
                  });

                  this.$router.push(`/sales/orders/${res.data._id}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCanceledOrder() {
      const h = this.$createElement;
      this.$msgbox({
        title: "BON DE COMMANDE N° " + this.order.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler ce bon de commande ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(this.order._id, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.order.status = "CANCELED";
                  this.fetchStat();
                  this.$message({
                    type: "success",
                    message: "Le bon de commande a été annulé",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleValidDoc() {
      this.validLoanding = true;
      validDocument(this.order._id, this.typeDoc)
        .then(() => {
          setTimeout(() => {
            this.$message({
              message: "La commande  a été validé",
              type: "success",
            });
            this.order.status = "OPEND";
            this.validLoanding = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.validLoanding = false;
            this.$message({
              message: err.data.message,
              type: "error",
            });
          }, 1.5 * 1000);
        });
    },
    handleCloseTransfDialog(e) {
      this.dialogTransVisible = e;
    },
    handleOpenTransfDialog() {
      this.dialogTransVisible = true;
    },
    handleTab(e) {
      const name = e.name;
      if (name === "INVOICE") {
        this.getInvoiceData();
      }
    },
    async getInvoiceNo() {
      await getDocumentSaleNo("INVOICE").then((res) => {
        this.invoice.document_no = res.data.number;
      });
    },
    handleConverToInvoice() {
      this.dialogInvoice = true;
      this.getInvoiceNo();
      this.invoice.line_items = this.order.line_items.map((e) => {
        return {
          article_id: e.article_id,
          article_no: e.article_sku,
          article_name: e.article_name,
          description: "",
          price: e.price_sales,
          percent_discount: e.percent_discount,
          percent_tax: e.percent_tax,
          discount: e.percent_discount ? e.rate_discount : e.discount,
          quantity: e.quantity - e.quantity_invoiced,
          warehouse_code: e.warehouse_code,
          res_quantity: e.quantity - e.quantity_invoiced,
        };
      });
    },
    handleAction(e) {
      switch (e) {
        case "close":
          this.handleClosedOrder();
          break;
        case "copy":
          this.handleCopyOrder();
          break;
        case "cancel":
          this.handleCanceledOrder();
          break;

        case "update":
            this.$router.push(`/sales/orders/update/${this.order._id}`);
          break;
      }
    },
    createInvoice(invoiceForm) {
      this.$refs[invoiceForm].validate((valid) => {
        if (valid) {
          this.loadingInvoice = true;
          converOrderToInvoice(this.invoice, this.order._id)
            .then(() => {
              setTimeout(() => {
                this.loadingInvoice = false;
                this.fetchData();
                this.dialogInvoice = false;
                this.$message({
                  message: "Facture créee avec succès",
                  type: "success",
                });
              }, 1.5 * 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                this.loadingInvoice = false;
                if (err.data.status === 400) {
                  this.$message({
                    message:
                      "Veuillez renseigner les informations obligatoire et les articles",
                    type: "warning",
                  });
                }

                if (err.data.status === 409) {
                  this.$message({
                    message: err.data.message,
                    type: "danger",
                  });
                }
              }, 1.5 * 1000);
            });
        }
      });
    },
    async getInvoiceData() {
      this.listLoading = true;
      await getAllDocumentReference(this.order.number, "INVOICE")
        .then((res) => {
          setTimeout(() => {
            this.invoices = res.data;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleDeleteItemInvoice(articleId) {
      const index = this.invoice.line_items.findIndex(
        (el) => el.article_id === articleId
      );
      this.invoice.line_items.splice(index, 1);
    },
    exportOrderPDF() {
      exportOrderPDF(this.order._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleView() {
      this.dialogViewPDF = true;
      exportOrderPDF(this.order._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-article {
  height: 60vh;
  overflow-y: auto;
}
</style>
